@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

@layer utilities {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

body {
  font-family: Lexend;
  font-style: normal;
  font-weight: 400;
}

@layer base {

  .text-primary {
    font-family: Lexend;
  font-style: normal;
  font-weight: 400;
  }

  
  .text-secondary {
    font-family: neuropol-x-light, sans-serif !important;
    font-style: normal;
    font-weight: 700;
  }

  h1, h2, h3, h4, h5, h6 { 
    @apply font-bold mb-5 tablet:mb-10 text-white leading-10;
    font-family: neuropol-x-light, sans-serif !important;
    font-style: normal;
    font-weight: 700;
  }

  h1 {
    @apply text-4xl tablet:text-5xl;

    &.hero {
      @apply tablet:text-6xl;
    }
  }
  h2 {
    @apply text-3xl tablet:text-3xl;
  }

  h3 {
    @apply text-xl laptop:text-2xl;
  }

  .bg-primary {
    @apply bg-[#ec2829];
  }

  .btn {
    @apply bg-red-500 border-4 rounded-full px-8 py-2 cursor-pointer hover:drop-shadow-2xl transition-shadow focus:outline-none text-white text-xl tablet:text-3xl;
  
    &.btn-primary{
      @apply bg-[#ec2829];
    }
  
    &.btn-secondary {
      @apply bg-[#FF9700];
    }
  }

  .btn-sm {
    @apply tablet:text-lg;
  }

  .btn-alt {
      @apply bg-[#48a5aa];
      border-top: 3px solid white;
      border-bottom: 3px solid #008890;
  }

  p {
    @apply mb-4 tablet:mb-6
  }

}

.content-container{

  // @apply flex w-screen h-full justify-center items-center;
  @apply py-[4rem] m-auto;

  @screen mobile {
    @apply py-[6rem];
  }

  @screen tablet {
    @apply py-[7rem];
  }
  
  @screen desktop {
    @apply py-[8rem];
  }
}
  
